.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.ms-16px {
	margin-left: 16px;
}

.mt-16px {
	margin-top: 16px;
}

.mx-16px {
	margin-left: 16px;
	margin-right: 16px;
}

.mb-8px {
	margin-bottom: 8px;
}

.mt-24px {
	margin-top: 24px;
}

.mt-12px {
	margin-top: 12px;
}

.mt-32px {
	margin-top: 32px;
}

.mt-48px {
	margin-top: 48px;
}

.mb-24px {
	margin-bottom: 24px;
}

.me-24px {
	margin-right: 24px;
}

.me-16px {
	margin-right: 16px;
}

.me-12px {
	margin-right: 12px;
}

.m-4px {
	margin: 4px;
}

.ms-8px {
	margin-left: 8px;
}

.mt-8px {
	margin-top: 8px;
}

.mb-16px {
	margin-bottom: 16px;
}

.mt-30px {
	margin-top: 30px;
}

.ms-24px {
	margin-left: 24px;
}

.my-32px {
	margin-top: 32px;
	margin-bottom: 32px;
}

.m-8px {
	margin: 8px;
}

.ms-48px {
	margin-left: 48px;
}

.m-6px {
	margin: 6px;
}

.mb--120px {
	margin-bottom: -120px;
}

.mb-32px {
	margin-bottom: 32px;
}

.mb-4px {
	margin-bottom: 4px;
}

.my-24px {
	margin-top: 24px;
	margin-bottom: 24px;
}

.me-8px {
	margin-right: 8px;
}

.mt-64px {
	margin-top: 64px;
}

