.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.px-8px {
	padding-left: 8px;
	padding-right: 8px;
}

.py-4px {
	padding-top: 4px;
	padding-bottom: 4px;
}

.p-32px {
	padding: 32px;
}

.py-6px {
	padding-top: 6px;
	padding-bottom: 6px;
}

.p-4px {
	padding: 4px;
}

.p-8px {
	padding: 8px;
}

.px-12px {
	padding-left: 12px;
	padding-right: 12px;
}

.ps-24px {
	padding-left: 24px;
}

.px-16px {
	padding-left: 16px;
	padding-right: 16px;
}

.p-16px {
	padding: 16px;
}

.pe-24px {
	padding-right: 24px;
}

.p-12px {
	padding: 12px;
}

.p-24px {
	padding: 24px;
}

.ps-16px {
	padding-left: 16px;
}

.pe-16px {
	padding-right: 16px;
}

.pb-24px {
	padding-bottom: 24px;
}

.px-32px {
	padding-left: 32px;
	padding-right: 32px;
}

.pt-150px {
	padding-top: 150px;
}

.pb-32px {
	padding-bottom: 32px;
}

.px-72px {
	padding-left: 72px;
	padding-right: 72px;
}

.py-12px {
	padding-top: 12px;
	padding-bottom: 12px;
}

.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}

.pb-8px {
	padding-bottom: 8px;
}

.ps-8px {
	padding-left: 8px;
}

.pt-12px {
	padding-top: 12px;
}

.p-3 {
	padding: 3%;
}

