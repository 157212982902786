.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}

@font-face {
	font-family: sixcapsregular;
	src: url('../../assets/fonts/SixCaps-Regular.ttf');
}

.ff-sixcapsregular {
font-family: sixcapsregular;
}

@font-face {
	font-family: librebaskervilleregular;
	src: url('../../assets/fonts/LibreBaskerville-Regular.ttf');
}

.ff-librebaskervilleregular {
font-family: librebaskervilleregular;
}

@font-face {
	font-family: librebaskervillebold;
	src: url('../../assets/fonts/LibreBaskerville-Bold.ttf');
}

.ff-librebaskervillebold {
font-family: librebaskervillebold;
}

@font-face {
	font-family: antonregular;
	src: url('../../assets/fonts/Anton-Regular.ttf');
}

.ff-antonregular {
font-family: antonregular;
}

@font-face {
	font-family: title;
	src: url('../../assets/fonts/LibreBaskerville-Bold.ttf');
}

.ff-title {
font-family: title;
}

@font-face {
	font-family: label;
	src: url('../../assets/fonts/LibreBaskerville-Regular.ttf');
}

.ff-label {
font-family: label;
}

@font-face {
	font-family: admin_title;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-admin_title {
font-family: admin_title;
}

@font-face {
	font-family: admin_regular;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-admin_regular {
font-family: admin_regular;
}



.f-54px {
	font-size: 54px;
}

.f-32px {
	font-size: 32px;
}

.f-30px {
	font-size: 30px;
}


