.cursor-pointer {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.border-top,
.border-end,
.border-bottom,
.border-start {
  &.form-select,
  &.form-control,
  > .form-select,
  > .form-control {
    border: none;
  }
}

.border-bottom {
  &:not(.border-top.border-end.border-bottom) {
    border-radius: 0 !important;
  }
}


.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-10 {
  z-index: 10;
}


.border-radius-50px {
	border-radius: 50px !important;
}

.border-radius-5px {
	border-radius: 5px !important;
}

.border-radius-32px {
	border-radius: 32px !important;
}

.border-radius-64px {
	border-radius: 64px !important;
}

.border-radius-15px {
	border-radius: 15px !important;
}

.border-radius-10px {
	border-radius: 10px !important;
}

.border-radius-tl15px-tr15px {
	border-radius: 15px 15px 0 0 !important;
}

.border-radius-250px {
	border-radius: 250px !important;
}

.border-radius-100px {
	border-radius: 100px !important;
}

