$btn-white-space: nowrap;
$primary: #DF8B8E;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #8FBF83,
	'primary': #DF8B8E,
	'primary-dark': #9a5053,
	'primary-light': #E3D3D1,
	'primary-text': #20221D,
	'secondary-text': #757575,
	'text-on-primary': #ffffff,
	'text-on-accent': #000000,
	'light': #F8F9Fa,
	'gray': #20221D,
	'green': #8FBF83,
	'orange': #DD7E50,
	'pink': #DF8B8E,
	'purple': #9480AC,
	'white': #E3D3D1,
	'yellow': #E7CE80,
	'orange-light': #DF9960,
	'turquoise': #7B9D94,
	'darken': #000000d6,
	'card_bg': #ffffff,
	'admin_background': #ffffff,
	'row_top': #ffffff,
	'row_bottom': #fafafa,
	'primary_trans': #df8b8ed6,
) !default;
