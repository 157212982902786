.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-200px {
	max-width: 200px !important;
}

.w-250px {
	width: 250px !important;
}

.w-300px {
	width: 300px !important;
}

.w-200px {
	width: 200px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.min-w-150px {
	min-width: 150px !important;
}

.min-w-120px {
	min-width: 120px !important;
}

.w-114px {
	width: 114px !important;
}

.w-40px {
	width: 40px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-150px {
	width: 150px !important;
}

.w-50px {
	width: 50px !important;
}

.w-400px {
	width: 400px !important;
}

.w-350px {
	width: 350px !important;
}

.min-w-200px {
	min-width: 200px !important;
}

.w-64px {
	width: 64px !important;
}

.w-24px {
	width: 24px !important;
}

.w-164px {
	width: 164px !important;
}

.max-w-600px {
	max-width: 600px !important;
}

.max-w-350px {
	max-width: 350px !important;
}

.max-w-250px {
	max-width: 250px !important;
}

.min-w-100px {
	min-width: 100px !important;
}

.max-w-300px {
	max-width: 300px !important;
}

.max-w-128px {
	max-width: 128px !important;
}

.min-w-96px {
	min-width: 96px !important;
}

.w-96px {
	width: 96px !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.max-w-130px {
	max-width: 130px !important;
}

.min-w-80px {
	min-width: 80px !important;
}

.w-256px {
	width: 256px !important;
}

.w-100px {
	width: 100px !important;
}

.w-128px {
	width: 128px !important;
}

.w-48px {
	width: 48px !important;
}

.w-16 {
	width: 16% !important;
}

.w-50 {
	width: 50% !important;
}

.min-w-500px {
	min-width: 500px !important;
}

.max-w-150px {
	max-width: 150px !important;
}

.min-w-600px {
	min-width: 600px !important;
}

.min-w-140px {
	min-width: 140px !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-75px {
	width: 75px !important;
}

.w-32px {
	width: 32px !important;
}

