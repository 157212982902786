@charset "UTF-8";

@import 'variables';

// Vendor
@import '~bootstrap/scss/bootstrap', 'vendor/bootstrap.custom';

// Components
@import 'components/component', 'components/group', 'components/text', 'components/nav', 'components/list', 'components/image', 'components/icon';

// Layout
@import 'layout/align', 'layout/base', 'layout/colors', 'layout/display',
  'layout/font', 'layout/height', 'layout/margin', 'layout/padding',
  'layout/position', 'layout/width';
